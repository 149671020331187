import React from 'react'
import { Link } from 'react-scroll'
import Picture from '../../../components/Picture'
import bannerTopoImg from '../../../assets/images/natal/banner-topo-lojas-2.png'
import bannerTopoMobileImg from '../../../assets/images/natal/banner-topo-lojas-2-mobile.png'

import * as S from './styles'

const BannerTopo = () => (
  <S.BannerTopo>
    <Link to="lojas" spy={true} smooth={true}>
      <Picture
        xs={bannerTopoMobileImg}
        md={bannerTopoImg}
        lg={bannerTopoImg}
        alt="Summer Sale"
      />
    </Link>
  </S.BannerTopo>
)

export default BannerTopo
